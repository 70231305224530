const summarizeDataAlert = {
    noVals: 'Inputs must be valid numbers',
    lessThanTwoVals: 'Input error: At least 2 values required'
}

const distributionAlert = {
    nAlert: 'n must be a whole number 30 or larger',
    X2Alert: 'x² must be a positive number',
    FAlert: 'F must be a positive number',
    betweenAlert: 'Leftmost number must be the smaller number',
    missingBetweenAlert: 'Both inputs required',
    firstInputBinomial: 'Valid inputs required for both n and p',
    firstInputDf: 'degrees of freedom must be input first',
    firstInputx: 'mean and standard deviation must be input first',
    firstInputxBar: 'mean, standard deviation and sample size must be input first',
}

const binomialAlert = {
    lessThan: 'number on the left must be smaller than the number on the right',
    lessOrEqual: 'number on the left must be smaller than or equal to the number on the right',
    betweenInput: 'A & B must be whole numbers between 0 and n',
    singleInput: 'x values must be a whole number from 0 to n'
}

const hypothesisAlert = {
    pAlert: 'p must be a positive number between 0 and 1',
    nxAlert: 'Sample size and Successes must be a whole number',
}

const regressionAlert = {
    minPairs: 'At least 3 ordered pairs required',
    missingPairs: 'Ordered pair missing',
    xBounds: 'x-value must be within the bounds of your input x-values',
    xEqual: 'Must input at least two distinct X values',
    invalidInputs: 'x and y inputs must be valid numbers'
}

export default {
    summarizeDataAlert,
    distributionAlert,
    binomialAlert,
    hypothesisAlert,
    regressionAlert
}
